import { API } from '../src/Constant'

export const ENDPOINTS = {
    USER_DETAILS: API + "/admin/grid",
    ACCOUNT_CLOSURE: API + "/admin/account-closure",
    HOME_VALUATION: API + "/admin/home-valuation-update",
    PROMO_CODES: API + "/admin/promocode-records",
    GIFTCARD_DASBOARD: API + "/admin/gift-card-dashboard",
    EQUIFAX_ADDRESS: API + "/admin/equifax-address",
    READ_CREDIT_REPORT: API + "/admin/read-credit-report",
    WITHDRAW_USER_AMOUNT: API + "/admin/withdraw-user-amount",
    TRANSFER_AMOUNT_TO_USER_WALLET: API + "/admin/transfer-amount-to-user-wallet",
    REFUND_AMOUNT_TO_USER_ACCOUNT: API + "/admin/refund-amount-to-user-account",
    USER_REMORTGAGE: API + "/admin/user-remortgage",
    USER_ACTIVITY: API + "/admin/user-account-activity",
    TRANSFER_REWARD_AMOUNT: API + "/admin/transfer-reward-amount",
    REDIS_CACHE_CLEANER: API + "/admin/redis-cache-clean",
    ADD_PROMOCODE: API + "/admin/add-promocode",
    ADD_REFERRAL: API + "/admin/add-referral",
    UPDATE_PAY_TO_LENDER: API + "/admin/update-pay-to-lender",
    UPDATE_PENDING_TASK: API + "/admin/update-pending-task",
    UPDATE_USER_PENDING_ACTION: API + "/admin/update-user-pending-action",
    VERIFICATION_LINK: API  + "/admin/verification-link",
    PPS_PAYEE: API + "/admin/pps-payee",
    DELETE_USER: API + "/admin/delete-user",
    MANAGE_USER: API + "/admin/manage-user",
    PN_NOTIFIER: API + "/admin/pn-notifier",
    MASTER_TO_USER_EWALLET_FAILURE: API + "/admin/master-to-user-ewallet-failure",
    AML_VERIFICTION_PPS: API + "/admin/aml-verification-pps",
    TOGGLE_DIRECT_DEBIT: API + "/admin/toggle-direct-debit",
    UPDATE_GIFT_CARD_BLACKLIST: API + "/admin/update-gift-card-blacklist",
    REMORTGAGE_LETTER: API + "/admin/remortgage-letter",
    GENERATE_BROKER_REPORT: API + "/admin/generate-broker-report",
    ADD_AFFILIATES_REWARDS: API + "/admin/add-affiliates-reward",
    CANCEL_GIFT_CARD: API + "/admin/cancel-gift-card",  
    REMOVE_USER_FROM_SUPPRESSION_LIST: API + "/admin/remove-user-from-suppression-list",
    REMOVE_PIN_FOR_USER: API + "/admin/remove-pin-for-user",
    FORCE_LOGOUT_USER: API + "/admin/force-logout-user",
    USER_MORTGAGE_DATA: API + "/admin/user-mortgage-data",
    RESET_CREDIT_REPORT_PULL_ATTEMPTS: API + "/admin/reset-credit-report-pull-attempts"
}
export default ENDPOINTS;
