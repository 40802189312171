import * as React from "react";
import { Fragment, useState } from "react";

import { Field, withTypes } from "react-final-form";
import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Notification, useTranslate, useNotify} from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import Check from '@material-ui/icons/Check';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CustomConfirm from 'ra-custom-confirm';
import {getConfigGet, getConfigPost} from "../getConfig";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  tableCell: {
    padding:'0px 10px 5px 10px',
    border:'1px solid gray' 
  },
  tooltip: {
    padding: '0px'
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  EmailOrId?: string | number;
}


const { Form } = withTypes<FormValues>();

const UserDetail = () => {

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  
  const handleSubmit = (inputData: any) => {
    setDetails([])
    setUserEmail(inputData.EmailOrId)
    notify("pos.fetching_the_result");

    let config 
    if(isNaN(Number(inputData.EmailOrId))){
      config = getConfigGet({email: inputData.EmailOrId})  
    }else{
      if(inputData.EmailOrId.length<10){
        config = getConfigGet({id: inputData.EmailOrId})
      }else{
        config = getConfigGet({pps_account_number: inputData.EmailOrId})
      }
    }
  
  axios.get(ENDPOINTS.USER_ACTIVITY, config)
    .then((res: any) => {
        setTableList(res?.data[0].tableList)
        setDetails(res?.data[0].activityInfo)  
        setLoading(false);
        notify(res?.data?.data || 'Success', "info", "", undefined, 20000);
      })
      .catch((error) => {
        notify(error?.response?.data?.message || 'Failed get user account detail', "error", "", undefined, 50000)
      });

  };

 
  const validate = (values: FormValues) => {
      const errors: FormValues = {};
      if (!values.EmailOrId) {
        errors.EmailOrId = translate("ra.validation.required");
      }
      return errors;
    };


  return (
    <>
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <div>
          <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  autoFocus
                  name="EmailOrId"
                  // @ts-ignore
                  component={renderInput}
                  label={translate("pos.buttons.email_or_user_id")}
                  disabled={loading}
                />
              </div>
            </div>
            <CardActions className={classes.actions}>
              <Button
                variant="contained"
                type="submit"
                color='primary'
                disabled={loading}
                fullWidth
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                  Fetch User Details
                </Button>
            </CardActions>
          
          
            <Notification />
          </div>
          </form>
       
        {details.length?
                <>
                    {details.map((detail: any, index:number)=>(
                            <div>
                                <h3>{tableList[index]}</h3>
                                <ShowData record={detail} tableCenterAlign={false}/>
                            </div>
                        )
                    )}
                    <WithdrawButton userEmail={userEmail}/>
                 
                    <TransferAmountToUser userEmail={userEmail}/>
                    <AmlVerificationXml userEmail={userEmail}/>
                    <ResetCreditReportPullAttempts userEmail={userEmail}/>
                </>: null}
        </div>
        
      )}
    />
    </>
  );
};

const WithdrawButton = (props: any) => {
  const [open, setOpen] = useState(false);
  const [withdrawalData,  setWithdrawalData] = useState("")
  const [isWithdrawButtonClicked, setIsWithdrawButtonClicked] = useState(false)
  const classes = useStyles();
  
  const notify = useNotify();

  const handleClick = (event: any) => {
    event.stopPropagation(); 

    let config = getConfigGet({email: props.userEmail})           
   
    axios.get(ENDPOINTS.WITHDRAW_USER_AMOUNT, config)
    .then((res: any) => {
        setWithdrawalData(res.data)
        setOpen(true);
      })
      .catch((error) => {
        notify(error?.response?.data?.message || 'Failed get user withdrawal info', "error", "", undefined, 50000)
      });

  };

  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
  if(!isWithdrawButtonClicked){
    setIsWithdrawButtonClicked(true); 
    let headers = getConfigPost()
    let data = {
      email: props.userEmail
    }
    
    axios.post(ENDPOINTS.WITHDRAW_USER_AMOUNT, data, {headers})
      .then((res: any) => {
        notify(res?.data?.data || 'Success', "info", "", undefined, 50000);
      }).catch((error) => {
        notify(error?.response?.data?.message || 'Failed to Withdraw user amount', "error", "", undefined, 50000)
      }).finally(() => {
        handelResetFlags()
      })
    }else{
      notify('please wait for one minute between two transactions or refresh the page.', "error", "", undefined, 5000)
     } 
  };

  const handelResetFlags = () => {
    setOpen(false);
    setTimeout(()=>{
      setIsWithdrawButtonClicked(false)
    }, 60000)
  }
  return (
    <Fragment>
      <>
      <div className={classes.main}>
      <Tooltip 
        className={classes.tooltip} 
        title="Source: User's E-wallet, Destination: User's Bank Account">
        <CardActions className={classes.actions}>
          <Button
                variant="contained"
                type="submit"
                color='primary'
                fullWidth
                onClick={handleClick}
                >Withdraw Funds to User
              </Button>
            </CardActions>
      </Tooltip>
        </div>
          <CustomConfirm 
            isOpen={open}
            title="Please verify the details"  
            user={withdrawalData}   
            content={CustomConfirmContent} 
            confirm='OK'                 
            confirmColor='primary'     
            ConfirmIcon={Check}       
            cancel='Cancel'     
            CancelIcon={ErrorOutline}  
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
      </>
    </Fragment>
  );
}

const TransferAmountToUser = ({userEmail} : any) => {

  const classes = useStyles();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [transferData,  setTransferData] = useState("");
  const [endPoint, setEndPoint] = useState("") 
  const [amount, setAmount] = useState("");
  const [istransferAmountClicked, setIstransferAmountClicked] = useState(false)

  const onAmountEntered = (e: any) => {
    setAmount(e.target.value)
  }

  const handleClick = (endpoint: any) => {
    setEndPoint(endpoint)
    let config = getConfigGet({email: userEmail})           
    axios.get(endpoint, config)
    .then((res: any) => {
        setTransferData(res.data)
        setOpen(true);
    })
    .catch((error) => {
        notify(error?.response?.data?.message || 'Failed get recipient info', "error", "", undefined, 50000)
    });  
  };
  
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {

    if(!istransferAmountClicked){
      setIstransferAmountClicked(true)
      let headers = getConfigPost()
      let data = {
        email: userEmail,
        amount: amount
      }
      axios.post(endPoint, data, {headers})
        .then((res: any) => {
          setAmount("")
          notify(`Success:: ${data.amount} deposited`, "info", "", undefined, 50000);
        })
        .catch((error) => {
          notify(error?.response?.data?.message || 'Failed to deposite the fund', "error", "", undefined, 50000)
        }).finally(() => {
          handelResetFlags()
        });
    }else{
      notify('please wait for one minute between two transactions or refresh the page.', "error", "", undefined, 5000)
    }
  };

  const handelResetFlags = () => {
    setOpen(false);
    setTimeout(()=>{
      setIstransferAmountClicked(false)
    }, 60000)
  }

  return(
    <Fragment>
      <>
        <div className={classes.main}>  
          <div className={classes.form}>
            <TextField 
                  label = "Amount"
                  onChange={onAmountEntered}
                  value={amount}
            />
          </div>
          <CardActions className={classes.actions}>
            <Tooltip
              className={classes.tooltip}
              title="Source: Sprive Rewards Account, Destination: User's E-wallet"
            >
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  color='primary'
                  fullWidth
                  onClick={()=>handleClick(ENDPOINTS.TRANSFER_AMOUNT_TO_USER_WALLET)}
                  disabled = {!amount.length}
                  >Deposit Funds to User Wallet
                </Button>
              </div>
            </Tooltip>
            <Tooltip
              className={classes.tooltip}
              title="Source: Sprive Rewards Account, Destination: User's Bank Account"
            >
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  color='primary'
                  fullWidth
                onClick={()=>handleClick(ENDPOINTS.REFUND_AMOUNT_TO_USER_ACCOUNT)}
                disabled = {!amount.length}
                >Deposit Funds to User Bank
                  </Button>
              </div>
            </Tooltip>
          </CardActions>
        </div>
        <CustomConfirm 
            isOpen={open}
            title="Please verify the details"  
            user={transferData}   
            content={CustomConfirmContent} 
            confirm='OK'                 
            confirmColor='primary'     
            ConfirmIcon={Check}       
            cancel='Cancel'     
            CancelIcon={ErrorOutline}  
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
      </>
    </Fragment>
  )
}

const AmlVerificationXml = (props: any) => {
  const classes = useStyles();
  
  const notify = useNotify();

  const handleClick = () => {

    const config = getConfigGet({email: props.userEmail})
    
    axios.get(ENDPOINTS.AML_VERIFICTION_PPS, config)
    .then((res: any) => {
      
        let fileData = 
        "Name: " + res.data[0] + "\n" +
        "Address: " + res.data[1] + "\n" +
        "Aml xml: " + res.data[2]

        const element = document.createElement("a");
        const file = new Blob([fileData], {type: 'text/plain;charset=utf-8'});
        element.href = URL.createObjectURL(file);
        element.download = res.data[0] + "_aml.txt";
        document.body.appendChild(element);
        element.click();

        notify('Success', "info", "", undefined, 50000);
      })
      .catch((error) => {
        notify(error?.response?.data?.message || 'Failed to get aml verification xml', "error", "", undefined, 50000)
      });
      
  };

  return (
    <Fragment>
      <>
      <div className={classes.main}>
        <CardActions className={classes.actions}>
          <Button
                variant="contained"
                type="submit"
                color='primary'
                fullWidth
                onClick={handleClick}
                >Download Aml Verifiction XML
              </Button>
            </CardActions>
        </div>
      </>
    </Fragment>
  );
}
const ResetCreditReportPullAttempts = (props: any) => {
  const classes = useStyles();
  
  const notify = useNotify();

  const handleClick = () => {

    let headers = getConfigPost();

    let data = {
      email: props.userEmail,
    };

    axios.post(ENDPOINTS.RESET_CREDIT_REPORT_PULL_ATTEMPTS, data, { headers })
    .then((res: any) => {
        notify('Success', "info", "", undefined, 50000);
      })
      .catch((error) => {
        notify(error?.response?.data?.message || 'Failed to reset credit report pull attemp', "error", "", undefined, 50000)
      });
      
  };

  return (
    <Fragment>
      <>
      <div className={classes.main}>
        <CardActions className={classes.actions}>
          <Button
                variant="contained"
                type="submit"
                color='primary'
                fullWidth
                onClick={handleClick}
                >Reduce Credit Report Pull Attempts
              </Button>
            </CardActions>
        </div>
      </>
    </Fragment>
  );
}

const CustomConfirmContent = (props:any) => {
  const record:any = props.user
  return (
    <>
      <ShowData record={record} tableCenterAlign={true} />
    </>
  );
};

const ShowData = ({record, tableCenterAlign}: any) => {
  const classes = useStyles();
        return (
        <>
        <Table style={{display:!tableCenterAlign?'block':'', paddingBottom:'25px'}}>
          <TableBody>
            {record.map((r: any, index: number) => (
               
               <>
                {(index===0 &&  Array.isArray(r[0])) ? 
                   <>
                      <TableRow>
                          {r[0]
                          .map((d: any, index: any) => (
                              <>
                              {index%2 === 0 ?
                              <TableCell colSpan={r[0][index+1]} className={classes.tableCell} style={{textAlign:'center'}}>
                                  {d}
                              </TableCell>
                              : '' }
                              </>
                          ))}
                          </TableRow>
                          <TableRow>
                            {r[1].map((d: any) => (
                                <TableCell className={classes.tableCell} style={{textAlign:!tableCenterAlign?'center':'left'}}>
                                    {d}
                                </TableCell>
                            ))}
                          </TableRow>
                       </>
                :  <TableRow>
                    {r.map((d: any) => (
                        <TableCell className={classes.tableCell} style={{textAlign:!tableCenterAlign?'center':'left'}}>
                            {d}
                        </TableCell>
                    ))}
                </TableRow>}
                
                </>
            ))}
          </TableBody>
        </Table>
        </>
    )
}



export default UserDetail;
